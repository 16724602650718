import { ReactNode } from 'react';

const ButtonClickArea = ({ children, onClick, styling }: { children: ReactNode; onClick: () => void; styling?: string }) => {
    return (
        <div onClick={onClick} className={`-m-4 inline-flex cursor-pointer rounded-full p-4 ${styling ? styling : ''}`}>
            {children}
        </div>
    );
};

export default ButtonClickArea;
